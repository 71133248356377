import React from 'react';
import AboutText from '../components/about/about-page/AboutText';
import MissionAndVision from '../components/about/about-page/MissionAndVision';
import Contact from '../components/contact/Contact';
import Layout from '../components/layout';
import Seo from '../components/seo';
import PageHeader from '../components/services/service-page/PageHeader';
import CoreValues from '../components/core-values/CoreValues';

const AboutPage = () => {
  return (
    <Layout>
      <PageHeader title="About Us" />
      <AboutText />
      <MissionAndVision />
      <CoreValues />
      <Contact />
    </Layout>
  );
};

export default AboutPage;

export const Head = () => (
  <Seo title="ICS" />
);
