import React from 'react';

const MissionAndVision = () => {
  return (
    <div className="surface-ground px-4 py-8 md:px-6 lg:px-8">
      <div className="font-bold text-5xl text-blue-500 mb-3">
        Mission & Vision
      </div>
      <div className="flex flex-column xl:flex-row">
        <div className="flex shadow-2 bg-blue-500 border-round mr-0 xl:mr-4 mb-6 xl:mb-0 flex-column md:flex-row">
          <div className="p-4">
            <div className="flex justify-content-between mb-3">
              <span className="text-900 font-bold">Mission Statement</span>
            </div>
            <div className="line-height-3 text-white text-sm mb-3">
              To provide exceptional home and community-based care, Midway Homes
              Adult Care is dedicated to enhancing the well-being and
              independence of individuals. With compassion and professionalism,
              we deliver personalized support services, fostering a safe,
              nurturing environment that empowers individuals to lead fulfilling
              lives with dignity and respect.
            </div>
          </div>
        </div>
        <div className="flex shadow-2 bg-blue-500 border-round flex-column md:flex-row">
          <div className="p-4">
            <div className="flex justify-content-between mb-3">
              <span className="text-900 font-bold">Vision Statement</span>
            </div>
            <div className="line-height-3 text-white text-sm mb-3">
              Empowering lives through compassionate care, Midway Homes Adult
              Care envisions a future where every individual thrives with
              dignity, independence, and community integration. We strive to be
              a leading provider of comprehensive services, enriching lives and
              fostering a sense of belonging in the lives of those we serve.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MissionAndVision;
