import React from 'react';
import Value from './Value';

const CoreValues = () => {
  return (
    <div className="surface-section px-4 py-8 md:px-6 lg:px-8">
      <div className="font-bold text-5xl text-blue-500 mb-3">Our Core Values</div>
      <div className="text-700 text-xl line-height-3 mb-5">Guiding Principles for Exceptional Care.</div>
      <div className="grid nogutter">
        <Value title="Compassion" des="Demonstrating empathy, kindness, and understanding towards individuals in our care, their families, and our staff members." />
        <Value title="Integrity" des="Upholding the highest ethical standards, honesty, and transparency in all our interactions and decision-making processes." />
        <Value title="Person-Centered Approach" des="Placing individuals at the center of their care, valuing their preferences, needs, and aspirations in all aspects of our services." />
        <Value title="Safety and Well-being" des="Prioritizing the safety, well-being, and security of the individuals we support, implementing robust protocols and measures to ensure their welfare." />
        <Value title="Accountability" des="Taking responsibility for our actions, commitments, and the impact we have on the lives of the individuals we serve." />
        <Value title="Community Engagement" des="Taking responsibility for our actions, commitments, and the impact we have on the lives of the individuals we serve." />
      </div>
    </div>
  );
};

export default CoreValues;
