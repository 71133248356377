import React from 'react';

const Value = ({ title, des }) => {
  return (
    <div className="col-12 lg:col-4 pt-4 pe-4 pb-4">
      <div className="border-top-3 border-blue-500" />
      <div className="text-900 font-medium text-xl line-height-3 mb-4">{title}</div>
      <div className="font-sm text-700 line-height-3">
        {des}
      </div>
    </div>
  );
};

export default Value;
